import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import Home from './components/Home';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';

import Packages from './components/Packages';
import Stakings from './components/Stakings';
import IncomeAddress from './components/IncomeAddress';

import Users from './components/Users';

import WithdrawalRequest from './components/WithdrawalRequest';

import ReferralReward from './components/ReferralReward';

import UsersOffer from './components/UsersOffer';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import UserReport from './components/UserReport';
import Swap from './components/Swap';
import Newsletter from './components/Newsletter';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';

import copy from "copy-to-clipboard";
import DatewiseStakings from './components/DatewiseStakings';
import DatewiseWithdrawalRequest from './components/DatewiseWithdrawalRequest';


const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    var curl = window.location.href.split('/#')

    // console.log("curl", curl);

    const copyaddress = (address) => {
        copy(address);
    }

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: 'Explorer',
            items: [
                { label: 'Dashboard', icon: 'pi pi-th-large', to: '/dashboard' },
                { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users' },
                { label: "Packages", icon: "pi pi-fw pi-list", to: "/packages" },
                { label: "Stakings", icon: "pi pi-fw pi-list", to: "/stakings" },
                { label: 'Income Address', icon: 'pi pi-fw pi-list', to: '/income-address' },
                { label: 'Referral Reward', icon: 'pi pi-fw pi-list', to: '/referral-reward' },
                { label: 'Withdrawal Requests', icon: 'pi pi-fw pi-list', to: '/withdrawal-request' },
                { label: 'Users(Offer)', icon: 'pi pi-fw pi-list', to: '/users-offer' },
                { label: 'Swap', icon: 'pi pi-fw pi-list', to: '/swap' },
                { label: 'Newsletter', icon: 'pi pi-fw pi-list', to: '/newsletter' },
                { label: "Stakings Report", icon: "pi pi-fw pi-list", to: "/datewise_stakings" },
                { label: 'Withdrawal Report', icon: 'pi pi-fw pi-list', to: '/withdrawal-report' }             

            ]
        },

    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <>
            {curl[1] === '/' ?

                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Route path="/" exact render={() => <Home colorMode={layoutColorMode} location={location} />} />
                        </div>
                    </div>

                    <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                </div>
                :
                <div className={wrapperClass} onClick={onWrapperClick}>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar onToggleMenuClick={onToggleMenuClick} rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                        layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>

                    <div className="layout-main-container">
                        {/* <Route path="/" exact component={Home} /> */}
                        <div className="layout-main">

                            {/* <Route path="/" exact component={Home} /> */}
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/profile" component={Profile} />

                            <Route path="/packages" exact component={Packages} />
                            <Route path="/stakings" exact component={Stakings} />
                            <Route path="/datewise_stakings" exact component={DatewiseStakings} />
                            <Route path="/income-address" exact component={IncomeAddress} />

                            <Route path="/Users" exact component={Users} />

                            <Route path="/withdrawal-request" exact component={WithdrawalRequest} />
                            <Route path="/withdrawal-report" exact component={DatewiseWithdrawalRequest} />

                            <Route path="/referral-reward" exact component={ReferralReward} />
                            <Route path="/users-offer" exact component={UsersOffer} />

                            <Route path="/userreport/:id" exact component={UserReport} />
                            <Route path="/swap" exact component={Swap} />
                            <Route path="/newsletter" exact component={Newsletter} />

                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>

                    <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                        <div className="layout-mask p-component-overlay"></div>
                    </CSSTransition>

                </div>
            }
        </>
    );

}

export default App;
