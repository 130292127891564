import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import axios from 'axios';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Packages = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }

    const dt = useRef(null);

    const [packagedata, setpackagedata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchPackageListdata = async () => {
        console.log("Search PackageList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "package", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search PackageList Data is", res.data.data);
                if (res.data.data) {
                    setpackagedata(res.data.data);
                }
            })
    }
    const Getpackagedata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "package", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("Package Data is", res.data.data);
                console.log("Package DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setpackagedata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchPackageListdata();
        }
        else {
            Getpackagedata();
        }

    }, [loading, first, rows]);


    const StakingMonthBody = (rowData) => {
        return (
            <>
                <span>{rowData.months} Months</span>
            </>
        );
    }
    const MinBodyTemplate = (rowData) => {
        return (
            <>
                <span>{rowData.min} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }
    const MaxBodyTemplate = (rowData) => {
        return (
            <>
                <span>{rowData.max} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }

    const BonusBody = (rowData) => {
        return (
            <>
                <span>{rowData.bonus_per} %</span>
            </>
        );
    }
    const MonthlyRewardBody = (rowData) => {
        return (
            <>
                <span>{rowData.monthly_reward_per} %</span>
            </>
        );
    }

    const LevelIncomeBody = (rowData) => {
        return (
            <>
                <b>Level: </b>
                <span>{rowData.level}</span> <br />

                <b>Ref Inc: </b>
                <span>{rowData.refinc} %</span>
            </>
        );
    }

    const StatushBody = (rowData) => {
        return (
            <>
                <div className="actionsOne">

                    {rowData.status ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : <Badge value="Inactive" className="mr-3 mt-2" severity="danger"></Badge>}

                </div>
            </>
        );
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

            <h5 className="m-0">Packages</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getpackagedata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchPackageListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }
                    
                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Packages</title>

            <div className="grid AdvertisementList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={packagedata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Packages"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={packagedata.id} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="name" header="Package Name" sortable body={packagedata.name} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="stakigmonth" header="Staking Months" sortable body={StakingMonthBody} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="min" header="Min" sortable body={MinBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                            <Column field="max" header="Max" sortable body={MaxBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                            <Column field="bonus_per" header="Bonus Per (%)" sortable body={BonusBody} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="monthly_reward_per" header="Monthly Reward Per (%)" sortable body={MonthlyRewardBody} headerStyle={{ width: '14%', minWidth: '17rem' }}></Column>
                            <Column field="level_income" header="Level Income (%)" sortable body={LevelIncomeBody} headerStyle={{ width: '14%', minWidth: '15rem' }}></Column>
                            <Column field="status" header="Status" sortable body={StatushBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Packages, comparisonFn);