import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';

import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import copy from "copy-to-clipboard";


const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};


const StakingReport = (props) => {

    const { id } = useParams();

    const copyaddress = (address) => {
        copy(address);
    }
    const location = useLocation();

    const dt = useRef(null);

    var user_name = sessionStorage.getItem("user_name");

    const [stakingdata, setstakingdata] = useState([]);

    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchTransactionListdata = async () => {
        console.log("Search TransactionList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "transaction", submethod: "searchbyid", from_address: props.id, input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search TransactionList Data is", res.data.data);
                if (res.data.data) {
                    setstakingdata(res.data.data);
                }
            })
    }
    const Getstakingdata = async page => {
        console.log("propsidddddd", props.id);
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "transaction", submethod: "getallbyfromaddress", from_address: props.id, key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("Staking Data is", res.data.data);
                console.log("Staking DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setstakingdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchTransactionListdata();
        }
        else {
            Getstakingdata();
        }

    }, [loading, first, rows]);


    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <b># </b>
                <span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.hash}`} target="_blank">{rowData.hash.substr(0, 5) + "..." + rowData.hash.substr(-5, 5)}</a> </span>
                <span onClick={(e) => copyaddress(rowData.hash)} id={rowData.hash}><i className='pi pi-copy' /></span>
                <br />

                <b>From: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.from_address}`} target="_blank">{rowData.from_address.substr(0, 5) + "..." + rowData.from_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.from_address)} id={rowData.from_address}><i className='pi pi-copy' /></span>
                <br />

                <b>To: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.to_address}`} target="_blank">{rowData.to_address.substr(0, 5) + "..." + rowData.to_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.to_address)} id={rowData.to_address}><i className='pi pi-copy' /></span>
            </>
        );
    }

    const AmountBody = (rowData) => {
        return (
            <>
                <b>Staked Arma: </b>
                <span>
                    $ {rowData.staked_arma}
                </span>
                <br />

                <b>Total Bonus: </b>
                <span>
                    $ {rowData.total_bonus}
                </span>
            </>
        );
    }

    const ArmaBody = (rowData) => {
        return (
            <>
                <b>Total Arma: </b>
                <span>
                    $ {rowData.total_arma}
                </span>
                <br />

                <b>Arma Rate: </b>
                <span>
                    $ {rowData.arma_rate}
                </span>
            </>
        );
    }

    const PackageInfoBody = (rowData) => {
        return (
            <>
                <b>ID: </b>
                <span>
                    {rowData.pkg_id}
                </span>
                <br />

                <b>Months: </b>
                <span>
                    {rowData.pkg_months} Months
                </span>
                <br />

                <b>Bonus: </b>
                <span>
                    {rowData.pkg_bonus}
                </span>
                <br />

                <b>Reward: </b>
                <span>
                    {rowData.pkg_reward}
                </span>
            </>
        );
    }

    const DateInfoBody = (rowData) => {
        return (
            <>
                <b>Date: </b>
                <span>
                    {rowData.datetime}
                </span> <br />
                <b>Next Reward Date: </b>
                <span>
                    {rowData.datetime}
                </span> <br />
                <b>End Date: </b>
                <span>
                    {rowData.datetime}
                </span>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Staking List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />
                   
                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getstakingdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchTransactionListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );


    return (

        <>
            <DataTable ref={dt} value={stakingdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of Staking"
                SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
            >

                <Column field="id" header="Sr." sortable body={stakingdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>

                <Column field="transactiondetails" header="Transaction Details" sortable body={addressBodyTemplate} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                <Column field="amount" header="Amount" sortable body={AmountBody} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                <Column field="aarma" header="Arma" sortable body={ArmaBody} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                <Column field="packageinfo" header="Package Info" sortable body={PackageInfoBody} headerStyle={{ width: '15%', minWidth: '25rem' }}></Column>

                <Column field="date" header="date" sortable body={DateInfoBody} headerStyle={{ width: '20%', minWidth: '25rem' }}></Column>

            </DataTable>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default StakingReport;