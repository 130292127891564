import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import axios from 'axios';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};


const WithdrawalRequest = (props) => {

    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    }

    const [product, setProduct] = useState();
    const [productDialog, setProductDialog] = useState(false);
    const [CancelProductDialog, setCancelProductDialog] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [withid, setwithid] = useState('');

    const [withdrawaldata, setwithdrawaldata] = useState([]);
    const [withdrawaldata1, setwithdrawaldata1] = useState([]);

    const [withdrawaldatabyid, setwithdrawaldatabyid] = useState([]);

    const [hash, sethash] = useState('');

    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);


    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchWithdrawalRequestListdata = async () => {
        console.log("Search WithdrawalRequestList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdrawal", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search WithdrawalRequestList Data is", res.data.data);
                if (res.data.data) {
                    setwithdrawaldata(res.data.data);
                }
            })
    }
    const Getwithdrawaldata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdrawal", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                if (res.data.data) {
                    setwithdrawaldata(res.data.data);
                    setwithdrawaldata1(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchWithdrawalRequestListdata();
        }
        else {
            Getwithdrawaldata();
        }

    }, [loading, first, rows]);

    const amountBody = (rowData) => {
        return (
            <>

                <b>Received: </b>
                <span>
                    <Badge value={`${rowData.receive} ${process.env.REACT_APP_TICKER}`} className="mr-3 mt-2" severity="success"></Badge>
                </span>
                <br />

                <b>Gas Fees: </b>
                <span>
                    {rowData.gas_fee} {process.env.REACT_APP_TICKER}
                </span>
                <br />

                <b>Amount: </b>
                <span>
                    {rowData.amount} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">{rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}><i className='pi pi-copy' /></span>
                <br />

                {rowData.hash ?
                    <><b># </b><span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.hash}`} target="_blank">{rowData.hash.substr(0, 5) + "..." + rowData.hash.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.hash)} id={rowData.hash}><i className='pi pi-copy' /></span></>
                    : ''}
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                <b>Request Date:</b>&nbsp;
                {rowData.datetime} <br />
                <b>Approval Date:</b>&nbsp;
                {rowData.apr_datetime}
            </>
        );
    }

    const openNew = (id) => {
        Getwithdrawaldatabyid(id);
        console.log(",", id);
        setwithid(id);
        setProduct();
        setSubmitted(false);
        setProductDialog(true);
    }

    const openNewCancel = (id) => {
        Getwithdrawaldatabyid(id);
        console.log(",", id);
        setwithid(id);
        setProduct();
        setSubmitted(false);
        setCancelProductDialog(true);
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.status === 0 ? <><Button icon="pi pi-check" className="p-button-rounded  mr-2 mb-2" onClick={(e) => openNew(rowData._id)} /><Button icon="pi pi-times" className="p-button-rounded p-button-danger  mr-2 mb-2" onClick={(e) => openNewCancel(rowData._id)} /></> : ''}

                {rowData.status === 2 ? <Badge value="Cancelled" className="mr-3 mt-2" severity="danger"></Badge> : ''}

                {rowData.status === 1 ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : ''}

            </div>
        );
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setCancelProductDialog(false);
    }


    const Getwithdrawaldatabyid = async (id) => {
        console.log("withdrawaldata1.id", id);
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdrawal", submethod: "getbyid", id: id, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("WithdrawalRequest new Data is", res.data.data[0]);
                console.log("WithdrawalRequest new DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setwithdrawaldatabyid(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    const updateWithdrawal = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdrawal", submethod: "updatehash", id: withid, hash: hash, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Updated Successfully", res.data);
                Getwithdrawaldata(1);
                hideDialog();
                actionBodyTemplate();
            })

    }

    const updateWithdrawalStatusCancel = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "withdrawal", submethod: "updatestatus", id: withid, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Updated Successfully", res.data);
                Getwithdrawaldata(1);
                hideDialog();
                actionBodyTemplate();
            })

    }

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateWithdrawal} />
        </>
    );

    const cancelproductDialogFooter = (
        <>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={updateWithdrawalStatusCancel} />
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Withdrawal</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getwithdrawaldata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchWithdrawalRequestListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Withdrawal Requests</title>

            <div className="grid SocialMediaList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={withdrawaldata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Withdrawal"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={withdrawaldata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="address" header="Address" sortable body={addressBodyTemplate} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>
                            <Column field="link" header="Amount" sortable body={amountBody} headerStyle={{ width: '15%', minWidth: '15rem' }}></Column>
                            <Column field="link" header="Date" sortable body={dateBody} headerStyle={{ width: '14%', minWidth: '20rem' }}></Column>
                            <Column field="action" header="Status" body={actionBodyTemplate} headerStyle={{ width: '14%', minWidth: '20rem' }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: '450px' }} header="Approve Request" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                            <div className="field">
                                <label htmlFor="name">Available Balance : </label> {withdrawaldatabyid.availablebalance}
                            </div>
                            <div className="field">
                                <label htmlFor="email">Amount Requested *</label>
                                <InputText id="email" defaultValue={withdrawaldatabyid.amount} type="email" readOnly />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Hash #</label>
                                <InputText id="hash" onChange={(e) => sethash(e.target.value)} type="text" required />
                            </div>

                        </Dialog>

                        <Dialog visible={CancelProductDialog} style={{ width: '450px' }} header="Cancel Request" modal className="p-fluid" footer={cancelproductDialogFooter} onHide={hideDialog}>

                            <div className="field">
                                <label htmlFor="name">Are you sure to cancel the request?</label>
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WithdrawalRequest, comparisonFn);