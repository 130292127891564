import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};


const Users = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }

    const dt = useRef(null);

    var user_name = sessionStorage.getItem("user_name");

    const [usersdata, setusersdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchUserListdata = async () => {
        console.log("Search UserList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "user", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search UserList Data is", res.data.data);
                if (res.data.data) {
                    setusersdata(res.data.data);
                }
            })
    }
    const Getusersdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "user", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("Users Data is", res.data.data);
                console.log("Users DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setusersdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchUserListdata();
        }
        else {
            Getusersdata();
        }

    }, [loading, first, rows]);

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">{rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}><i className='pi pi-copy' /></span>
                <br />

                <b>R. Add: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.reff_address}`} target="_blank">{rowData.reff_address ? rowData.reff_address.substr(0, 5) + "..." + rowData.reff_address.substr(-5, 5) : ''}</a> </span><span onClick={(e) => copyaddress(rowData.reff_address)} id={rowData.reff_address}><i className='pi pi-copy' /></span>
                <br />

                <b>I. Add: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.income_address}`} target="_blank">{rowData.income_address.substr(0, 5) + "..." + rowData.income_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.income_address)} id={rowData.income_address}><i className='pi pi-copy' /></span>
            </>
        );
    }

    const incomeBodyTemplate = (rowData) => {
        return (
            <>
                <b>L.Inc: </b>
                <span>{rowData.total_level_income} {process.env.REACT_APP_TICKER}</span>
                <br />

                <b>B.Inc: </b>
                <span>{rowData.total_bonus_income} {process.env.REACT_APP_TICKER}</span>
                <br />

                <b>S.Inc: </b>
                <span>{rowData.total_staking_income} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }

    const InvestBody = (rowData) => {
        return (
            <>
                <span>{rowData.total_aarma} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }

    const WithdrawtBody = (rowData) => {
        return (
            <>
                <span>{rowData.total_withdraw} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }

    const PWithdrawBody = (rowData) => {
        return (
            <>
                <span>{rowData.pending_withdraw} {process.env.REACT_APP_TICKER}</span>
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <b>Register: </b>
                <span>{rowData.datetime}</span>
                <br />

                <b>Active: </b>
                <span>{rowData.activedatetime}</span>
            </>
        );
    }

    const StatushBody = (rowData) => {
        return (
            <>
                <div className="actionsOne">

                    {rowData.status ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : <Badge value="Inactive" className="mr-3 mt-2" severity="danger"></Badge>}

                </div>
            </>
        );
    }

    const ActionBody = (rowData) => {
        return (
            <>
                <div className="actionsOne">
                    <button className='p-button p-component p-button-raised p-button-success mr-2 mb-2' onClick={() => history.push(`/userreport/${rowData.address}`, { state: { address: rowData.address } })}>Report</button>
                </div>
            </>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">

                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getusersdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchUserListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );


    return (

        <>

            <title>Users</title>

            <div className="grid WalletUsersList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={usersdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Users"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={usersdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>

                            <Column field="address" header="Address" sortable body={addressBodyTemplate} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                            <Column field="total_aarma" header="Invest" sortable body={InvestBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                            <Column field="income" header="Income" sortable body={incomeBodyTemplate} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                            <Column field="total_withdraw" header="Withdraw" sortable body={WithdrawtBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                            <Column field="pending_withdraw" header="P.Withdraw" sortable body={PWithdrawBody} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                            <Column field="total_reff_count" header="Ref" sortable body={usersdata.total_reff_count} headerStyle={{ width: '7%', minWidth: '7em' }}></Column>

                            <Column field="datetime" header="Datetime" sortable body={dateBodyTemplate} headerStyle={{ width: '15%', minWidth: '20rem' }}></Column>

                            <Column field="status" header="Status" sortable body={StatushBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="action" header="" sortable body={ActionBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>


                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);