import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';

import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';
import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const LevelTransactionReport = (props) => {

    const { id } = useParams();

    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    }

    const [leveltransactionsdata, setleveltransactionsdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchLevelTransactionsListdata = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "leveltransaction", submethod: "searchbyid", to_address: props.id, input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                if (res.data.data) {
                    setleveltransactionsdata(res.data.data);
                }
            })
    }
    const Getleveltransactionsdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "leveltransaction", submethod: "getbytoaddress", to_address: props.id, key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                if (res.data.data) {
                    setleveltransactionsdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;


        if (SearchInput !== '') {
            SearchLevelTransactionsListdata();
        }
        else {
            Getleveltransactionsdata();
        }

    }, [loading, first, rows]);


    const transactionBody = (rowData) => {
        return (
            <>
                <b># </b>
                <span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.pkg_hash}`} target="_blank">{rowData.pkg_hash ? rowData.pkg_hash.substr(0, 5) + "..." + rowData.pkg_hash.substr(-5, 5) : ''}</a> </span><span onClick={(e) => copyaddress(rowData.pkg_hash)} id={rowData.pkg_hash}><i className='pi pi-copy' /></span>
                <br />

                <b>From: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.from_address}`} target="_blank">{rowData.from_address ? rowData.from_address.substr(0, 5) + "..." + rowData.from_address.substr(-5, 5) : ''}</a> </span><span onClick={(e) => copyaddress(rowData.from_address)} id={rowData.from_address}><i className='pi pi-copy' /></span>
                <br />

                <b>To: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.to_address}`} target="_blank">{rowData.to_address ? rowData.to_address.substr(0, 5) + "..." + rowData.to_address.substr(-5, 5) : ''}</a> </span><span onClick={(e) => copyaddress(rowData.to_address)} id={rowData.to_address}><i className='pi pi-copy' /></span>

            </>
        );
    }

    const amountBody = (rowData) => {
        return (
            <>
                <b>First Part Income:</b> &nbsp;
                {rowData.income} ARMA
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Level Transactions List</h5>

            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getleveltransactionsdata()}>Search</span><span class="p-ink"></span></button>
                        :
                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchLevelTransactionsListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );


    return (

        <>
            <DataTable ref={dt} value={leveltransactionsdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of Level Transactions"
                SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
            >
                <Column field="id" header="Sr." sortable body={leveltransactionsdata.id} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>

                <Column field="name" header="Transaction" sortable body={transactionBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column field="ticker" header="Amount" body={amountBody} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                <Column field="datetime" header="Date" body={leveltransactionsdata.datetime} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

            </DataTable>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default LevelTransactionReport;