import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import axios from 'axios';

const Profile = () => {
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [error, seterror] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const btnupdate = async (e) => {            
        if (password !== '') {
            axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "adminLogin", submethod: "update", newpassword: password, key: process.env.REACT_APP_KEY })
                .then(res => {
                    if (res.data === 0) {
                        seterror("Data Updated Successfully");
                        sessionStorage.clear();
                        history.push('/');

                    } else {
                        seterror("Error Occured");
                    }
                })
        }
        else {
            seterror("Please Enter New Password");
        }
    }

    return (
        <>

            <title>Profile</title>
            <div className="card mdb">
                <div className="grid">
                    <div className="col-12 md:col-12">
                        <div className="card p-fluid">
                            <h5><b>Update Profile</b></h5>
                            <div className="field">
                                <label htmlFor="name">Username *</label>
                                <InputText type="text" id="user_name" defaultValue={user_name} disabled />
                            </div>
                            <div className="field">
                                <label htmlFor="password">New Password *</label>
                                <InputText id="password" type="password" onChange={(e) => setpassword(e.target.value)} required />
                                <span className='text-danger'>{error}</span><br />
                            </div>
                            <div className="field grid">
                                <div className="col-12 md:col-2">
                                    <Button type="button" label="Update" onClick={btnupdate}></Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Profile, comparisonFn);

