import React, { useReducer, useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const IncomeAddress = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }
    const dt = useRef(null);

    const [addressdata, setaddressdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchAddressListdata = async () => {
        console.log("Search AddressList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "address", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search AddressList Data is", res.data.data);
                if (res.data.data) {
                    setaddressdata(res.data.data);
                }
            })
    }
    const Getaddressdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "address", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("addresses Data is", res.data.data);
                console.log("addresses DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setaddressdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }


    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchAddressListdata();
        }
        else {
            Getaddressdata();
        }

    }, [loading, first, rows]);

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">{rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}><i className='pi pi-copy' /></span>
            </>
        );
    }
    const AssignToBodyTemplate = (rowData) => {
        return (
            <>
                <b><i class="pi pi-send" aria-hidden="true"></i> </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.assignaddress}`} target="_blank">{rowData.assignaddress.substr(0, 5) + "..." + rowData.assignaddress.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.assignaddress)} id={rowData.assignaddress}><i className='pi pi-copy' /></span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    {rowData.status ? <Badge value="Assigned" className="mr-3 mt-2" severity="success"></Badge> : <Badge value="Not assigned" className="mr-3 mt-2" severity="warning"></Badge>}
                </div>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Income Address List</h5>

            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getaddressdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchAddressListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }
                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Income Address</title>

            <div className="grid TokenList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={addressdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Addresses"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >


                            <Column field="id" header="Sr." sortable body={addressdata.id} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="address" header="Address" sortable body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="assignto" header="Assignto" sortable body={AssignToBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="action" header="Status" body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(IncomeAddress, comparisonFn);