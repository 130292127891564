import React, { useReducer, useState, useEffect, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';

import { Badge } from 'primereact/badge';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import copy from "copy-to-clipboard";


const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const ReferralReward = (props) => {

    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    }

    const [leveltransactionsdata, setleveltransactionsdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchLevelTransactionsListdata = async () => {
        console.log("Search LevelTransactionsList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "leveltransaction", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search LevelTransactionsList Data is", res.data.data);
                if (res.data.data) {
                    setleveltransactionsdata(res.data.data);
                }
            })
    }
    const Getleveltransactionsdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "leveltransaction", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("LevelTransactionsList Data is", res.data.data);
                console.log("LevelTransactionsList DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setleveltransactionsdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchLevelTransactionsListdata();
        }
        else {
            Getleveltransactionsdata();
        }

    }, [loading, first, rows]);

    const transactionBody = (rowData) => {
        return (
            <>
                <b># </b>
                <span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.pkg_hash}`} target="_blank">{rowData.pkg_hash.substr(0, 5) + "..." + rowData.pkg_hash.substr(-5, 5)}</a> </span>
                <span onClick={(e) => copyaddress(rowData.pkg_hash)} id={rowData.pkg_hash}><i className='pi pi-copy' /></span>
                <br />

                <b>From: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.from_address}`} target="_blank">{rowData.from_address.substr(0, 5) + "..." + rowData.from_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.from_address)} id={rowData.from_address}><i className='pi pi-copy' /></span>
                <br />

                <b>To: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.to_address}`} target="_blank">{rowData.to_address.substr(0, 5) + "..." + rowData.to_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.to_address)} id={rowData.to_address}><i className='pi pi-copy' /></span>

            </>
        );
    }

    const rewardBody = (rowData) => {
        return (
            <>
                <b>Amount:</b> &nbsp;
                {rowData.stakearma} {process.env.REACT_APP_TICKER} <br />

                <b>Reward:</b> &nbsp;
                {rowData.income} {process.env.REACT_APP_TICKER} <br />

                <b>Level:</b> &nbsp;
                {rowData.income_level}
            </>
        );
    }


    const StatushBody = (rowData) => {
        return (
            <>
                <div className="actionsOne">

                    {rowData.status ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : <Badge value="Inactive" className="mr-3 mt-2" severity="danger"></Badge>}

                </div>
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Level Transactions</h5>

            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getleveltransactionsdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchLevelTransactionsListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Referral Reward</title>

            <div className="grid ChainList-demo">
                <div className="col-12">
                    <div className="card">


                        <DataTable ref={dt} value={leveltransactionsdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Level Transactions"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={leveltransactionsdata.id} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>

                            <Column field="name" header="Transaction Details" sortable body={transactionBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="amt" header="Reward" body={rewardBody} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                            <Column field="datetime" header="Datetime" body={leveltransactionsdata.datetime} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                            <Column field="action" header="Status" body={StatushBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ReferralReward, comparisonFn);