import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import { Badge } from 'primereact/badge';
import axios from 'axios';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const UsersOffer = (props) => {

    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    }

    const [offerdata, setofferdata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchOfferListdata = async () => {
        console.log("Search OfferList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "offers", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search OfferList Data is", res.data.data);
                if (res.data.data) {
                    setofferdata(res.data.data);
                }
            })
    }
    const Getofferdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "offers", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("Offer Data is", res.data.data);
                console.log("Offer DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setofferdata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchOfferListdata();
        }
        else {
            Getofferdata();
        }

    }, [loading, first, rows]);

    const dateBody = (rowData) => {
        return (
            <>
                <b>Request Date:</b>&nbsp;
                {rowData.datetime} <br />
                <b>Approval Date:</b>&nbsp;
                {rowData.apr_datetime}
            </>
        );
    }

    const imageBody = (rowData) => {
        return (
            <>
                <img src={`assets/${rowData.img}`} alt={rowData.img} width="30px" />
            </>
        );
    }
    const selfStakeBody = (rowData) => {
        return (
            <>
                {rowData.self_stake} {process.env.REACT_APP_TICKER}
            </>
        );
    }
    const directMinimumBody = (rowData) => {
        return (
            <>
                {rowData.direct_minimum} {process.env.REACT_APP_TICKER}
            </>
        );
    }


    const statusBody = (rowData) => {
        return (
            <div className="actionsOne">

                {rowData.status ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : <Badge value="Inactive" className="mr-3 mt-2" severity="danger"></Badge>}

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getofferdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchOfferListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }

                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Users(Offer)</title>

            <div className="grid BackupWalletNoticeList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={offerdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Offer"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={offerdata.id} headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                            <Column field="description" header="Description" sortable body={offerdata.description} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="img" header="Image" sortable body={imageBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="self_stake" header="Self Stake" sortable body={selfStakeBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="direct_minimum" header="Direct Minimum" sortable body={directMinimumBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="start_date" header="Start Date" sortable body={offerdata.start_date} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="end_date" header="End Date" sortable body={offerdata.end_date} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="status" header="Status" sortable body={statusBody} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>


                        </DataTable>


                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UsersOffer, comparisonFn);