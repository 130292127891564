import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';

import axios from 'axios';
import StakingReport from './StakingReport';
import LevelTransactionReport from './LevelTransactionReport';
import WithdrawalRequestReport from './WithdrawalRequestReport';
import SwapReport from './SwapReport';


import { InputText } from 'primereact/inputtext';
import { ProductService } from '../service/ProductService';

import copy from "copy-to-clipboard";

const UserReport = (props) => {

    const { id } = useParams();

    const copyaddress = (address) => {
        copy(address);
    }
    const location = useLocation();

    const dt = useRef(null);

    var user_name = sessionStorage.getItem("user_name");

    const [usersdata, setusersdata] = useState([]);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        Getusersdatabyid();
        //Getstakingdata();

        if (SearchInput !== '') {
            SearchUserListdata();
        }

    }, []);

    const SearchUserListdata = async () => {

    }

    const Getusersdatabyid = async page => {
        // console.log("addddddddddrrrrrrrr", id);
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "user", submethod: "getbyid", address: id, key: process.env.REACT_APP_KEY })
            .then(res => {
                if (res.data.data) {
                    setusersdata(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Investments List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" onClick={() => SearchUserListdata(currentPage)} />
                <InputText type="Search" onChange={(e) => setSearchInput(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    return (

        <>
            <title>User Report</title>

            <div class="card">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5>User Details</h5>
                </div>
                <div className="grid">
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">User Address</span>
                                <div className="mt-1 text-600"><span><a href={`${process.env.REACT_APP_TAD_URL}${usersdata.address}`} target="_blank">{usersdata.address}</a> </span><span onClick={(e) => copyaddress(usersdata.address)} id={usersdata.address}><i className='pi pi-copy' /></span></div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Balance</span>
                                <div className="mt-1 text-600">{(usersdata.total_level_income + usersdata.total_staking_income) - (usersdata.total_withdraw + usersdata.pending_withdraw)} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">

                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Withdrawal</span>
                                <div className="mt-1 text-600">{usersdata.total_withdraw} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Pending Withdrawal</span>
                                <div className="mt-1 text-600">{usersdata.pending_withdraw} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Ref Address</span>
                                <div className="mt-1 text-600"><span><a href={`${process.env.REACT_APP_TAD_URL}${usersdata.reff_address}`} target="_blank">{usersdata.reff_address} </a> <span onClick={(e) => copyaddress(usersdata.reff_address)} id={usersdata.reff_address}><i className='pi pi-copy' /></span></span></div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Bonus Income</span>
                                <div className="mt-1 text-600">{usersdata.total_bonus_income} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 lg:col-6 xl:col-3">

                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Staking Income</span>
                                <div className="mt-1 text-600">{usersdata.total_staking_income} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">

                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Income</span>
                                <div className="mt-1 text-600">{usersdata.total_level_income} {process.env.REACT_APP_TICKER}</div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="grid">
                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Income Address</span>
                                <div className="mt-1 text-600"><span><a href={`${process.env.REACT_APP_TAD_URL}${usersdata.income_address}`} target="_blank">{usersdata.income_address}</a> </span> <span onClick={(e) => copyaddress(usersdata.income_address)} id={usersdata.income_address}><i className='pi pi-copy' /></span></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 lg:col-6 xl:col-3">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Total Referrals</span>
                                <div className="mt-1 text-600">{usersdata.total_reff_count}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-3">

                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="text-900 font-medium mr-2 mb-1 md:mb-0">Register Datetime</span>
                                <div className="mt-1 text-600">{usersdata.datetime}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="card mt-3">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5>Staking List</h5>
                </div>

                <StakingReport id={id} />
            </div>


            <div className="card mt-3">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5>Level Transactions</h5>
                </div>
                <LevelTransactionReport id={id} />
            </div>



            <div className="card mt-3">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5 className='fw-bolder'>Withdrawal</h5>
                </div>
                <WithdrawalRequestReport id={id} />
            </div>

            
            <div className="card mt-3">
                <div className="flex justify-content-between align-items-center mb-5">
                    <h5 className='fw-bolder'>Swap</h5>
                </div>
                <SwapReport id={id} />
            </div>


        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserReport, comparisonFn);