import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import axios from 'axios';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Newsletter = (props) => {

    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    }

    const [product, setProduct] = useState();
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [withid, setwithid] = useState('');

    const [newsletterdata, setnewsletterdata] = useState([]);
    const [newsletterdata1, setnewsletterdata1] = useState([]);

    const [newsletterdatabyid, setnewsletterdatabyid] = useState([]);

    const [hash, sethash] = useState('');

    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);


    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchNewsLetterListdata = async () => {
        console.log("Search NewsLetterList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "newsletter", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search NewsLetterList Data is", res.data.data);
                if (res.data.data) {
                    setnewsletterdata(res.data.data);
                }
            })
    }
    const Getnewsletterdata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "newsletter", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                if (res.data.data) {
                    console.log("newsletter data is", res.data.data);
                    setnewsletterdata(res.data.data);
                    setnewsletterdata1(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchNewsLetterListdata();
        }
        else {
            Getnewsletterdata();
        }

    }, [loading, first, rows]);


    const emailBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.email}
            </>
        );
    }

    const dateBody = (rowData) => {
        return (
            <>
                {rowData.datetime}
            </>
        );
    }

    const openNew = (id) => {
        console.log("rowData._id is=>", id);
        setwithid(id);
        setProduct();
        setSubmitted(false);
        setProductDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2 mb-2" onClick={(e) => openNew(rowData._id)} />

            </div>
        );
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const DeleteNewsletterbyID = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "newsletter", submethod: "delete", id: withid, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Deleted Successfully", res.data);
                Getnewsletterdata(1);
                hideDialog();
                actionBodyTemplate();
            })

    }

    const productDialogFooter = (
        <>
            <Button label="Delete" icon="pi pi-check" className="p-button-text" onClick={DeleteNewsletterbyID} />
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Newsletter</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
              
                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Getnewsletterdata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchNewsLetterListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }
                    
                </div>
                
            </span>
        </div>
    );

    return (

        <>

            <title>Newsletter</title>

            <div className="grid SocialMediaList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={newsletterdata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Swap"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={newsletterdata.id} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '18%', minWidth: '18rem' }}></Column>
                            <Column field="datetime" header="Datetime" sortable body={dateBody} headerStyle={{ width: '14%', minWidth: '20rem' }}></Column>
                            <Column field="action" header="Action" body={actionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        </DataTable>

                        <Dialog visible={productDialog} style={{ width: '450px' }} header="Delete" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                            <div className="field">
                                <label htmlFor="name">Are you sure to delete?</label>
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Newsletter, comparisonFn);