import React, { useReducer, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from '../service/ProductService';
import { Badge } from 'primereact/badge';

import copy from "copy-to-clipboard";

const init = initialState => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Stakings = (props) => {

    const copyaddress = (address) => {
        copy(address);
    }

    const [transactiondata, settransactiondata] = useState([]);
    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState('');

    const dt = useRef(null);

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push('/');
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchTransactionListdata = async () => {
        console.log("Search TransactionList Data Input", SearchInput);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "transaction", submethod: "search", input: SearchInput, key: process.env.REACT_APP_KEY })
            .then(res => {
                console.log("Search TransactionList Data is", res.data.data);
                if (res.data.data) {
                    settransactiondata(res.data.data);
                }
            })
    }
    const Gettransactiondata = async page => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "transaction", submethod: "get", key: process.env.REACT_APP_KEY, page: (first + rows) / 10, per_page: perPage })
            .then(res => {
                console.log("Transaction Data is", res.data.data);
                console.log("Transaction DataLength is", res.data.dataLength);
                if (res.data.data) {
                    settransactiondata(res.data.data);
                    setTotalRows(res.data.dataLength);
                }
            })
        setLoading(false);
    }

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== '') {
            SearchTransactionListdata();
        }
        else {
            Gettransactiondata();
        }

    }, [loading, first, rows]);

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <b># </b>
                <span><a href={`${process.env.REACT_APP_TXN_URL}${rowData.hash}`} target="_blank">{rowData.hash.substr(0, 5) + "..." + rowData.hash.substr(-5, 5)}</a> </span>
                <span onClick={(e) => copyaddress(rowData.hash)} id={rowData.hash}><i className='pi pi-copy' /></span>
                <br />

                <b>From: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.from_address}`} target="_blank">{rowData.from_address.substr(0, 5) + "..." + rowData.from_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.from_address)} id={rowData.from_address}><i className='pi pi-copy' /></span>
                <br />

                <b>To: </b>
                <span><a href={`${process.env.REACT_APP_TAD_URL}${rowData.to_address}`} target="_blank">{rowData.to_address.substr(0, 5) + "..." + rowData.to_address.substr(-5, 5)}</a> </span><span onClick={(e) => copyaddress(rowData.to_address)} id={rowData.to_address}><i className='pi pi-copy' /></span>
            </>
        );
    }

    const AmountBody = (rowData) => {
        return (
            <>
                <span>
                    <Badge value={`${rowData.total_arma} ${process.env.REACT_APP_TICKER}`} className="mr-3 mt-2" severity="success"></Badge>
                </span>
                <br />

                <b>Bonus: </b>
                <span>
                    {rowData.total_bonus} {process.env.REACT_APP_TICKER}
                </span>
                <br />

                <b>Staked: </b>
                <span>
                    {rowData.staked_arma} {process.env.REACT_APP_TICKER}
                </span>
                <br />

                <b>Amt: </b>
                <span>
                    $ {(rowData.staked_arma * rowData.arma_rate)}
                </span>
                <br />

                <b>Rate: </b>
                <span>
                    $ {rowData.arma_rate}
                </span>

            </>
        );
    }

    // const ArmaBody = (rowData) => {
    //     return (
    //         <>
    //             <b>Total Arma: </b>
    //             <span>
    //                 $ {rowData.total_arma}
    //             </span>
    //             <br />

    //             <b>Arma Rate: </b>
    //             <span>
    //                 $ {rowData.arma_rate}
    //             </span>
    //         </>
    //     );
    // }

    const PackageInfoBody = (rowData) => {
        return (
            <>
                <b>Months: </b>
                <span>
                    {rowData.pkg_months} Months
                </span>
                <br />

                <b>Bonus: </b>
                <span>
                    {rowData.pkg_bonus}
                </span>
                <br />

                <b>S. Reward: </b>
                <span>
                    {rowData.pkg_reward}
                </span>
            </>
        );
    }

    const DateInfoBody = (rowData) => {
        return (
            <>
                <b>Start: </b>
                <span>
                    {rowData.datetime}
                </span> <br />
                <b>Next Reward: </b>
                <span>
                    {rowData.datetime}
                </span> <br />
                <b>End: </b>
                <span>
                    {rowData.datetime}
                </span>
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.status === 0 ? <Badge value="Failed" className="mr-3 mt-2" severity="danger"></Badge> : ''}

                {rowData.status === 1 ? <Badge value="Active" className="mr-3 mt-2" severity="warning"></Badge> : ''}

                {rowData.status === 2 ? <Badge value="Completed" className="mr-3 mt-2" severity="success"></Badge> : ''}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Investments List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">

                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === '' ?

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => Gettransactiondata()}>Search</span><span class="p-ink"></span></button>

                        :

                        <button class="p-button p-component"><span class="p-button-label p-c" onClick={() => SearchTransactionListdata(currentPage)}>Search</span><span class="p-ink"></span></button>

                    }
                    
                </div>

            </span>
        </div>
    );

    return (

        <>

            <title>Stakings</title>

            <div className="grid NodesList-demo">
                <div className="col-12">
                    <div className="card">

                        <DataTable ref={dt} value={transactiondata} dataKey="id" paginator rows={10} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Staking"
                            SearchInput={SearchInput} emptyMessage="No data found." header={header} responsiveLayout="scroll"
                            totalRecords={totalRows} lazy first={first} onPage={e => dispatch({ type: "onPage", payload: e })}
                        >

                            <Column field="id" header="Sr." sortable body={transactiondata.id} headerStyle={{ width: '6%', minWidth: '8rem' }}></Column>

                            <Column field="addressdetails" header="Address Details" sortable body={addressBodyTemplate} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                            <Column field="transactiondetails" header="Transaction Details" sortable body={AmountBody} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column>

                            {/* <Column field="aarma" header="Arma" sortable body={ArmaBody} headerStyle={{ width: '10%', minWidth: '15rem' }}></Column> */}

                            <Column field="packageinfo" header="Package Info" sortable body={PackageInfoBody} headerStyle={{ width: '10%', minWidth: '20rem' }}></Column>

                            <Column field="date" header="Datetime" sortable body={DateInfoBody} headerStyle={{ width: '20%', minWidth: '25rem' }}></Column>

                            <Column field="action" header="Status" body={actionBodyTemplate} headerStyle={{ width: '5%', minWidth: '5rem' }}></Column>
                        </DataTable>


                    </div>
                </div>
            </div>

        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Stakings, comparisonFn);