import React from 'react';

export const AppFooter = (props) => {
    const todaysDate = new Date()
    const currentYear = todaysDate.getFullYear()

    return (
        <div className="layout-footer">
            {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/Ignite-logo.png' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" /> */}
            &copy; {currentYear} &nbsp;<b>Developed by</b>
            <span className="font-medium ml-2"><a href="https://aarma.io/" target="_blank">
                    Aarma
                </a></span>
        </div>
    );
}
