import React, { useReducer, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import axios from "axios";

import copy from "copy-to-clipboard";

const init = (initialState) => initialState;

//OJO: action deconstruido automaticamente en type y payload
const reducer = (state, { type, payload }) => {
    switch (type) {
        case "onPage":
            return { ...state, loading: true, first: payload.first };
        case "dataLoaded":
            return { ...state, results: payload, loading: false };
        default:
            throw new Error();
    }
};

const Swap = (props) => {
    const dt = useRef(null);
    const copyaddress = (address) => {
        copy(address);
    };

    const [product, setProduct] = useState();
    const [productDialog, setProductDialog] = useState(false);
    const [CancelProductDialog, setCancelProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [withid, setwithid] = useState("");
    const [approveId, setApproveId] = useState("");
    const [swapAmount, setSwapAmount] = useState(0);
    const [swapSpinner, setSwapSpinner] = useState(false);
    const [swapHash, setSwapHash] = useState("");
    const [disableApproveBtn, setDisableApproveBtn] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonErr, setReasonErr] = useState("");
    const [swapErr, setSwapErr] = useState("");

    const [swapdata, setswapdata] = useState([]);
    const [swapdata1, setswapdata1] = useState([]);

    const [swapdatabyid, setswapdatabyid] = useState([]);

    const [hash, sethash] = useState("");

    const [loading1, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [SearchInput, setSearchInput] = useState("");

    const history = useHistory();
    var user_name = sessionStorage.getItem("user_name");

    useEffect(() => {
        if (!user_name) {
            history.push("/");
        }
    }, []);

    const initialState = {
        results: [],
        loading: false,
        first: 0,
        rows: 10,
        totalRecords: totalRows,
    };
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const { results, loading, rows, first, totalRecords } = state;

    const SearchSwapListdata = async () => {
        console.log("Search SwapList Data Input", SearchInput);
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "swap",
                submethod: "search",
                input: SearchInput,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                console.log("Search SwapList Data is", res.data.data);
                if (res.data.data) {
                    setswapdata(res.data.data);
                }
            });
    };
    const Getswapdata = async (page) => {
        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "swap",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                page: (first + rows) / 10,
                per_page: perPage,
            })
            .then((res) => {
                if (res.data.data) {
                    console.log("swap data is", res.data.data);
                    setswapdata(res.data.data);
                    setswapdata1(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            });
        setLoading(false);
    };

    //pagination

    useEffect(() => {
        const startIndex = first;
        const endIndex = first + rows;

        console.log("startIndex ist", startIndex, rows, state);

        console.log("endIndex ist", endIndex / 10);

        if (SearchInput !== "") {
            SearchSwapListdata();
        } else {
            Getswapdata();
        }
    }, [loading, first, rows]);

    const amountBody = (rowData) => {
        return (
            <>
                <span>
                    {rowData.amount} {process.env.REACT_APP_TICKER}
                </span>
            </>
        );
    };

    const hashsBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.hash ? (
                    <>
                        <b># </b>
                        <span>
                            <a href={`${process.env.REACT_APP_TXN_URL}${rowData.hash}`} target="_blank">
                                {rowData.hash.substr(0, 5) + "..." + rowData.hash.substr(-5, 5)}
                            </a>{" "}
                        </span>
                        <span onClick={(e) => copyaddress(rowData.hash)} id={rowData.hash}>
                            <i className="pi pi-copy" />
                        </span>
                    </>
                ) : (
                    ""
                )}
                {rowData.approvedhash ? (
                    <>
                        <br />
                        <b># </b>
                        <span>
                            <a href={`${process.env.REACT_APP_TXN_URL}${rowData.approvedhash}`} target="_blank">
                                {rowData.hash.substr(0, 5) + "..." + rowData.approvedhash.substr(-5, 5)}
                            </a>{" "}
                        </span>
                        <span onClick={(e) => copyaddress(rowData.approvedhash)} id={rowData.approvedhash}>
                            <i className="pi pi-copy" />
                        </span>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    const fromaddBodyTemplate = (rowData) => {
        return (
            <>
                <b>
                    <i class="pi pi-send" aria-hidden="true"></i>{" "}
                </b>
                <span>
                    <a href={`${process.env.REACT_APP_TAD_URL}${rowData.address}`} target="_blank">
                        {rowData.address.substr(0, 5) + "..." + rowData.address.substr(-5, 5)}
                    </a>{" "}
                </span>
                <span onClick={(e) => copyaddress(rowData.address)} id={rowData.address}>
                    <i className="pi pi-copy" />
                </span>
                <br />
            </>
        );
    };

    const toaddBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.approvedaddress ? (
                    <>
                        <b>
                            <i class="pi pi-send" aria-hidden="true"></i>{" "}
                        </b>
                        <span>
                            <a href={`${process.env.REACT_APP_TAD_URL}${rowData.approvedaddress}`} target="_blank">
                                {rowData.approvedaddress.substr(0, 5) + "..." + rowData.approvedaddress.substr(-5, 5)}
                            </a>{" "}
                        </span>
                        <span onClick={(e) => copyaddress(rowData.approvedaddress)} id={rowData.approvedaddress}>
                            <i className="pi pi-copy" />
                        </span>
                        <br />
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    const appaddBodyTemplate = (rowData) => {
        return (
            <>
                <b>
                    <i class="pi pi-send" aria-hidden="true"></i>{" "}
                </b>
                <span>
                    <a href={`${process.env.REACT_APP_TAD_URL}${rowData.approvedaddress}`} target="_blank">
                        {rowData.approvedaddress.substr(0, 5) + "..." + rowData.approvedaddress.substr(-5, 5)}
                    </a>{" "}
                </span>
                <span onClick={(e) => copyaddress(rowData.approvedaddress)} id={rowData.approvedaddress}>
                    <i className="pi pi-copy" />
                </span>
                <br />
            </>
        );
    };

    const dateBody = (rowData) => {
        return (
            <>
                <b>Req.Date: </b>
                {rowData.req_datetime} <br />
                <b>Send Date: </b>
                {rowData.send_datetime}
            </>
        );
    };
    const reasonBody = (rowData) => {
        return <>{rowData.reason && rowData.status !== 0 && rowData.status !== 1 ? rowData.reason : ""}</>;
    };

    const openNew = (id, amt) => {
        Getswapdatabyid(id);
        setwithid(id);
        setSubmitted(false);
        setProductDialog(true);
        setApproveId(id);
        setSwapAmount(amt);
    };

    const openNewCancel = (id) => {
        Getswapdatabyid(id);
        console.log(",", id);
        setwithid(id);
        // setProduct();
        setSubmitted(false);
        setCancelProductDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.status === 0 ? (
                    <>
                        <Button icon="pi pi-check" className="p-button-rounded  mr-2 mb-2" onClick={(e) => openNew(rowData._id, rowData.amount)} />
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger  mr-2 mb-2"
                            onClick={(e) => openNewCancel(rowData._id)}
                        />
                    </>
                ) : (
                    ""
                )}

                {rowData.status === 2 ? <Badge value="Cancelled" className="mr-3 mt-2" severity="danger"></Badge> : ""}

                {rowData.status === 1 ? <Badge value="Active" className="mr-3 mt-2" severity="success"></Badge> : ""}
            </div>
        );
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setCancelProductDialog(false);
        setSwapSpinner(false);
        setSwapHash("");
    };

    const Getswapdatabyid = async (id) => {
        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "getbyid", id: id, key: process.env.REACT_APP_KEY })
            .then((res) => {
                console.log("Swap new Data is", res.data.data[0]);
                console.log("Swap new DataLength is", res.data.dataLength);
                if (res.data.data) {
                    setswapdatabyid(res.data.data[0]);
                    setTotalRows(res.data.dataLength);
                }
            });
        setLoading(false);
    };

    const updateswap = async () => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                method: "swap",
                submethod: "updatehash",
                id: withid,
                hash: hash,
                key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
                console.log("Updated Successfully", res.data);
                Getswapdata(1);
                hideDialog();
                actionBodyTemplate();
            });
    };
    const updateSwapStatusCancel = async () => {
        if (reason) {
            await axios
                .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
                    method: "swap",
                    submethod: "updatestatus",
                    id: withid,
                    key: process.env.REACT_APP_KEY,
                    reason: reason,
                })
                .then((res) => {
                    Getswapdata(1);
                    setReason("");
                    setReasonErr("");
                    hideDialog();
                    actionBodyTemplate();
                });
        } else {
            setReasonErr("Reason is require.");
        }
    };

    const approveTokens = async () => {
        setDisableApproveBtn(true);
        setSwapSpinner(true);
        setSwapErr("");
        await axios
            .post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "swap", submethod: "approve", id: approveId, key: process.env.REACT_APP_KEY })
            .then((res) => {
                if (res.data.error) {
                    setSwapErr(res?.data?.error);
                    setSwapHash("");
                    setSwapSpinner(false);
                    setDisableApproveBtn(false);
                } else {
                    setDisableApproveBtn(false);
                    setSwapSpinner(false);
                    setSwapHash(res.data.approvedhash);
                    Getswapdata();
                }
            })
            .catch((err) => {
                // console.log("error : ", err);
            });
    };

    // const productDialogFooter = (
    //     <>
    //         <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    //         {/* <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateswap} /> */}
    //     </>
    // );

    const cancelproductDialogFooter = (
        <>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={updateSwapStatusCancel} />
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">swap</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <div class="p-inputgroup">
                    <input placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} class="p-inputtext p-component" />

                    {SearchInput === "" ? (
                        <button class="p-button p-component">
                            <span class="p-button-label p-c" onClick={() => Getswapdata()}>
                                Search
                            </span>
                            <span class="p-ink"></span>
                        </button>
                    ) : (
                        <button class="p-button p-component">
                            <span class="p-button-label p-c" onClick={() => SearchSwapListdata(currentPage)}>
                                Search
                            </span>
                            <span class="p-ink"></span>
                        </button>
                    )}
                </div>
            </span>
        </div>
    );

    return (
        <>
            <title>swap Requests</title>

            <div className="grid SocialMediaList-demo">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dt}
                            value={swapdata}
                            dataKey="id"
                            paginator
                            rows={10}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of Swap"
                            SearchInput={SearchInput}
                            emptyMessage="No data found."
                            header={header}
                            responsiveLayout="scroll"
                            totalRecords={totalRows}
                            lazy
                            first={first}
                            onPage={(e) => dispatch({ type: "onPage", payload: e })}
                        >
                            <Column field="id" header="Sr." sortable body={swapdata.id} headerStyle={{ width: "7%", minWidth: "7rem" }}></Column>
                            <Column
                                field="action"
                                header="Status"
                                body={actionBodyTemplate}
                                headerStyle={{ width: "14%", minWidth: "10rem" }}
                            ></Column>
                            <Column
                                field="hash"
                                header="Hash"
                                sortable
                                body={hashsBodyTemplate}
                                headerStyle={{ width: "10%", minWidth: "15rem" }}
                            ></Column>
                            {/* <Column
                                field="name"
                                header="Name"
                                sortable
                                body={swapdata.name}
                                headerStyle={{ width: "15%", minWidth: "15rem" }}
                            ></Column> */}
                            <Column
                                field="from_address"
                                header="From Address"
                                sortable
                                body={fromaddBodyTemplate}
                                headerStyle={{ width: "15%", minWidth: "15rem" }}
                            ></Column>
                            <Column
                                field="to_address"
                                header="To Address"
                                sortable
                                body={toaddBodyTemplate}
                                headerStyle={{ width: "15%", minWidth: "15rem" }}
                            ></Column>
                            {/* <Column
                                field="to_address"
                                header="App. Address"
                                sortable
                                body={appaddBodyTemplate}
                                headerStyle={{ width: "15%", minWidth: "15rem" }}
                            ></Column> */}
                            <Column
                                field="amount"
                                header="Amount"
                                sortable
                                body={amountBody}
                                headerStyle={{ width: "15%", minWidth: "10rem" }}
                            ></Column>
                            <Column
                                field="datetime"
                                header="Datetime"
                                sortable
                                body={dateBody}
                                headerStyle={{ width: "14%", minWidth: "20rem" }}
                            ></Column>
                            <Column
                                field="reason"
                                header="Reason"
                                sortable
                                body={reasonBody}
                                headerStyle={{ width: "14%", minWidth: "20rem" }}
                            ></Column>
                        </DataTable>

                        <Dialog
                            visible={productDialog}
                            style={{ width: "450px" }}
                            header="Approve Request"
                            modal
                            className="p-fluid"
                            // footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            {/* <div className="field">
                                <label htmlFor="name">Available Balance : </label> {swapdatabyid.availablebalance}
                            </div> */}
                            <div className="field">
                                <h3 style={{ textAlign: "center" }}>
                                    <b>{swapAmount}</b> Aarma
                                </h3>
                                {/* <InputText id="email" defaultValue={swapdatabyid.amount} type="email" readOnly /> */}
                            </div>
                            <div className="field" style={{ textAlign: "center" }}>
                                {swapSpinner ? (
                                    <ProgressSpinner />
                                ) : !swapSpinner && !swapHash ? (
                                    <>
                                        {swapErr ? <h5 style={{ color: "#ff3333" }}>{swapErr}</h5> : ""}
                                        <Button
                                            style={{ width: "80%" }}
                                            label="Approve"
                                            className="p-button-danger"
                                            onClick={approveTokens}
                                            disabled={disableApproveBtn}
                                        />
                                    </>
                                ) : !swapSpinner && swapHash ? (
                                    <>
                                        <h4 style={{ color: "#4BB543" }}>Transaction successful.</h4>
                                        <b># </b>
                                        <span>
                                            <a
                                                href={`${process.env.REACT_APP_TXN_URL}${swapHash}`}
                                                target="_blank"
                                                style={{ wordBreak: "break-all" }}
                                            >
                                                {swapHash}
                                            </a>{" "}
                                        </span>
                                        <span onClick={(e) => copyaddress(swapHash)} id={swapHash}>
                                            <i className="pi pi-copy" />
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Dialog>

                        <Dialog
                            visible={CancelProductDialog}
                            style={{ width: "450px" }}
                            header="Cancel Request"
                            modal
                            className="p-fluid"
                            footer={cancelproductDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                {/* <label htmlFor="name">Are you sure to cancel the request?</label> */}
                                <label htmlFor="name">Reason : </label>

                                <div className="justify-content-center">
                                    <InputTextarea value={reason} onChange={(e) => setReason(e.target.value)} rows={3} cols={30} />
                                    {reasonErr ? <p className="text-danger">{reasonErr}</p> : ""}
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Swap, comparisonFn);
